import { defineStore } from "pinia";
import * as systemApi from "@/api/system-api";
import { errorHandler } from "@/package/global-helpers/error-handler";
import { IFeatureFlag } from "@/package/global-types";

interface INotification {
  type: "info" | "warning" | "success" | "error";
  title?: string;
  text?: string;
}

export const useSystemStore = defineStore("system-store", {
  state: () => ({
    notification: null as null | INotification,
    features: null as null | Array<IFeatureFlag>,
  }),

  actions: {
    async loadFeaturesFlags() {
      try {
        const resp = await systemApi.apiGetFeaturesFlags();
        this.features = resp.data.data.features;
      } catch (error: Error | unknown) {
        errorHandler(error, true);
        throw error;
      }
    },

    getFeatureFlag(flag: string) {
      return this.features.find((feature) => feature.name === flag);
    },

    showNotification({
      text = "",
      type = "error",
      title = "Что-то пошло не так...",
    }: INotification) {
      if (this.notification) {
        this.notification = null;
        setTimeout(() => {
          this.notification = {
            text,
            title,
            type,
          };
        }, 300);

        return;
      }

      this.notification = {
        text,
        title,
        type,
      };
    },

    hideNotification() {
      this.notification = null;
    },
  },
});
